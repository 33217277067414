import { Link } from "react-router-dom";
import { IMAGE_URL } from "../config";

/* BOOTSTRAP COMPONENTS */
import { Card, Button } from "react-bootstrap";

/* ICONS */
import { PhoneIcon } from "@heroicons/react/24/solid";
import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook-f";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";

const Footer = () => {
  return (
    <footer className="footer">
      {/* TOP FOOTER */}
      <div className="top-footer">
        <div className="container">
          <div className="row justify-content-between">
            {/* LOGO */}
            <div className="col-xl-2 col-lg-2 col-sm-12">
              <img
                src={`${IMAGE_URL}/logos/clement-career-college.webp`}
                className="logo"
                alt="Clement Career College"
              />
            </div>

            {/* NAVIGATION LINKS */}
            <div className="col-xl-5 col-lg-5 col-sm-12">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="footer-links">
                    <h5 className="heading">Consumer Disclosures</h5>

                    <a
                      href={`${IMAGE_URL}/School%20Performance%20Fack%20Sheet%202021-2022.pdf`}
                      target="_blank"
                      className="nav-link"
                      rel="noreferrer"
                    >
                      Performance Fact Sheet
                    </a>
                    <a
                      href={`${IMAGE_URL}/clement-career-college-2024-2025.pdf`}
                      target="_blank"
                      className="nav-link"
                      rel="noreferrer"
                    >
                      College Catalog
                    </a>
                    <Link to="/credit-transfer-policy" className="nav-link">
                      Credit Transfer Policy
                    </Link>
                    <a
                      href="https://www.bppe.ca.gov/"
                      target="_blank"
                      className="nav-link"
                      rel="noreferrer"
                    >
                      Bureau for Private and Post
                      <br />
                      Secondary Education
                    </a>
                    <Link to="/privacy-policy" className="nav-link">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="footer-links">
                    <h5 className="heading">Student Servces</h5>
                    <Link to="/advising-counseling" className="nav-link">
                      Advising &amp; Counseling
                    </Link>
                    <Link to="/employment-opportunity" className="nav-link">
                      Career &amp; Employment
                    </Link>
                    <Link to="/library-services" className="nav-link">
                      Library Services
                    </Link>
                    <Link to="/state-exam-preparation" className="nav-link">
                      State Exam Preparation
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* QUICK CONNECT SECTION */}
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <Card className="quick-connect-card">
                <Card.Body className="quick-connect-cardbody">
                  <h5 className="heading-text">Quick Connect</h5>
                  <a href="tel:+15625792111" className="phone">
                    <PhoneIcon className="icon" />
                    <h5 className="link">(562) 579-2111</h5>
                  </a>
                  <p className="address">
                    1225 West 190th Street, Suite 375 Gardena, CA 90248, United
                    States.
                  </p>
                  <div className="social-links">
                    <a
                      href="https://www.facebook.com/ClementCareerCollegeOfficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <FacebookIcon color="#FFF" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://www.instagram.com/clementcareercollege/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <InstagramIcon color="#FFF" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://twitter.com/ClementCareers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <TwitterIcon color="#FFF" size="18" />
                      </Button>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/clementcareercollege/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="social-media-btn">
                        <LinkedinIcon color="#FFF" size="18" />
                      </Button>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* COPYRIGHT FOOTER */}
      <div className="copyright-footer">
        <div className="container">
          <div className="copyright-row">
            <h5 className="text">
              © 2024 Clement Career College. Powered by{" "}
              <a
                href="https://roundtechsquare.com/"
                target="_blank"
                rel="noreferrer"
              >
                RoundTechSquare
              </a>
            </h5>
            <div className="accreditation">
              <img
                src={`${IMAGE_URL}/logos/accreditation-BPPE.webp`}
                className="accreditation-logo"
                alt="Bureau for Private Postsecondary Education"
              />
              <img
                src={`${IMAGE_URL}/logos/accreditation-BVNPT.webp`}
                className="accreditation-logo-bvnpt"
                alt="Board of Vocational Nursing and Psychiatric Technicians"
              />
              <img
                src={`${IMAGE_URL}/logos/california-department-of-public-health.webp`}
                className="accreditation-logo-cdp"
                alt="California Department of Public Health"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
